import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './SubmissionModal.module.css';

const SubmissionModal = ({ isOpen, onRequestClose, game, email, tsbUid }) => {
  const [score, setScore] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setScore('');
      setMinutes('');
      setSeconds('');
      setImage(null);
    }
  }, [isOpen]);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('email', email);
    formData.append('tsb_uid', tsbUid);
    formData.append('game', game);
    formData.append('score', score);
    formData.append('time', game === 'Trendy Docs' ? parseInt(minutes) * 60 + parseInt(seconds) : '');
    formData.append('image', image);

    const response = await fetch('/api/submit-score', {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();
    console.log(data);

    if (response.ok) {
      toast.success('Score submitted successfully!');
    } else {
      toast.error('Failed to submit score.');
    }

    onRequestClose();
  };

  return (
    <>
      <ToastContainer />
      <Modal isOpen={isOpen} onRequestClose={onRequestClose} className={styles.modal} overlayClassName={styles.overlay}>
        <h2>Submit Your Score for {game}</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <label>Email:</label>
            <p>{email}</p>
          </div>
          <div className={styles.formGroup}>
            <label>The Sandbox UserID:</label>
            <p>{tsbUid}</p>
          </div>
          <div className={styles.formGroup}>
            <label>Score:</label>
            <input type="number" value={score} onChange={(e) => setScore(e.target.value)} required />
          </div>
          {game === 'Trendy Docs' && (
            <div className={styles.formGroup}>
              <label>Time (MM:SS):</label>
              <div className={styles.timeInput}>
                <input type="number" value={minutes} onChange={(e) => setMinutes(e.target.value)} required />
                <span>:</span>
                <input type="number" value={seconds} onChange={(e) => setSeconds(e.target.value)} required />
              </div>
            </div>
          )}
          <div className={styles.formGroup}>
            <label>Upload Image:</label>
            <input type="file" accept="image/*" onChange={handleImageChange} required />
            {image && <img src={URL.createObjectURL(image)} alt="Preview" className={styles.imagePreview} />}
          </div>
          <button type="submit" className={styles.submitButton}>Submit</button>
        </form>
      </Modal>
    </>
  );
};

export default SubmissionModal;
