// src/components/SignUp.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import styles from './Auth.module.css';

Modal.setAppElement('#root');

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [tsb_uid, setTsbUid] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    const response = await fetch('/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password, tsb_uid }),
    });

    if (response.ok) {
      const user = await response.json();
      localStorage.setItem('user', JSON.stringify(user));
      window.dispatchEvent(new Event('loginStatusChanged'));
      setIsModalOpen(true);
    } else {
      console.error('Failed to sign up');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate('/');
  };

  return (
    <div className={styles.authContainer}>
      <h2 className={styles.authTitle}>Sign Up</h2>
      <form onSubmit={handleSignUp} className={styles.authForm}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className={styles.authInput}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className={styles.authInput}
        />
        <input
          type="text"
          placeholder="The Sandbox UserID"
          value={tsb_uid}
          onChange={(e) => setTsbUid(e.target.value)}
          required
          className={styles.authInput}
        />
        <button type="submit" className={styles.authButton}>Sign Up</button>
      </form>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Sign Up Confirmation"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Sign Up Successful</h2>
        <p>You have successfully signed up. Welcome!</p>
        <button onClick={closeModal} className={styles.authButton}>Close</button>
      </Modal>
    </div>
  );
};

export default SignUp;
