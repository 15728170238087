import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import styles from './Leaderboard.module.css';

const LeaderboardTrendyFactory = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const response = await fetch('/api/leaderboard-trendy-factory');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setLeaderboardData(data);
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      }
    };

    fetchLeaderboardData();
  }, []);

  return (
    <div className={styles.leaderboardContainer}>
      <h2 className={styles.leaderboardTitle}>
        <FontAwesomeIcon icon={faTrophy} className={styles.titleIcon} /> 
        <div>
          Leaderboard for <br /> Trendy Factory
        </div>
      </h2>
      <table className={styles.leaderboardTable}>
        <thead>
          <tr>
            <th>Position</th>
            <th>Name</th>
            <th>Score</th>
            <th>Estimated Reward (SAND)</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData.map((entry, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{entry.name}</td>
              <td>{entry.score}</td>
              <td>{entry.estimatedReward}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.leaderboardCardContainer}>
        {leaderboardData.map((entry, index) => (
          <div key={index} className={styles.leaderboardCard}>
            <h3>Position: {index + 1}</h3>
            <p><span>Name:</span> {entry.name}</p>
            <p><span>Score:</span> {entry.score}</p>
            <p><span>Estimated Reward (SAND):</span> {entry.estimatedReward}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeaderboardTrendyFactory;
