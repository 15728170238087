// src/components/Review.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Review.module.css';

Modal.setAppElement('#root');

const Review = () => {
  const [scores, setScores] = useState([]);
  const [selectedTab, setSelectedTab] = useState('Pending');
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchScores = async (status) => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = localStorage.getItem('token');
      if (!user || !token) {
        console.error('No user or token found in localStorage');
        return;
      }
      console.log('User found in localStorage:', user);

      const statusQuery = status === 'Archive' ? 'Approved,Rejected' : status;
      console.log(`Fetching ${status} scores with token: ${token}`);
      const response = await fetch(`/api/review-scores/${statusQuery}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch ${status} scores`);
      }

      const data = await response.json();
      console.log(`Data for ${status}:`, data);
      setScores(data);
    } catch (error) {
      console.error(`Error fetching ${status} scores:`, error);
    }
  };

  useEffect(() => {
    fetchScores(selectedTab);
  }, [selectedTab]);

  const handleStatusChange = async (id, status) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in localStorage');
        return;
      }
      const response = await fetch('/api/update-score-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id, status })
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      setScores(scores.filter(score => score.id !== id));
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status. Please try again.');
    }
  };

  const handleApprove = async (score) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in localStorage');
        return;
      }
      const { game_title, email, tsb_uid, score: playerScore, time_seconds } = score;
      const response = await fetch('/api/approve-score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ game_title, email, tsb_uid, score: playerScore, time: time_seconds })
      });

      if (response.ok) {
        await handleStatusChange(score.id, 'Approved');
        toast.success('Score approved and leaderboard updated.');
      } else {
        const data = await response.json();
        console.error('Failed to approve score:', data);
        toast.error('Failed to approve score. Please try again.');
      }
    } catch (error) {
      console.error('Error approving score:', error);
      toast.error('Error approving score. Please try again.');
    }
  };

  const handleMark = async (score) => {
    try {
      await handleStatusChange(score.id, 'Marked');
      toast.info('Score marked as suspicious.');
    } catch (error) {
      console.error('Error marking score:', error);
      toast.error('Error marking score. Please try again.');
    }
  };

  const handleReject = async (score) => {
    try {
      await handleStatusChange(score.id, 'Rejected');
      toast.info('Score rejected.');
    } catch (error) {
      console.error('Error rejecting score:', error);
      toast.error('Error rejecting score. Please try again.');
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className={styles.reviewContainer}>
      <ToastContainer />
      <h2 className={styles.reviewTitle}>Review Submitted Scores</h2>
      <div className={styles.tabs}>
        <button className={`${styles.tabButton} ${selectedTab === 'Pending' ? styles.active : ''}`} onClick={() => setSelectedTab('Pending')}>Pending</button>
        <button className={`${styles.tabButton} ${selectedTab === 'Marked' ? styles.active : ''}`} onClick={() => setSelectedTab('Marked')}>Marked</button>
        <button className={`${styles.tabButton} ${selectedTab === 'Archive' ? styles.active : ''}`} onClick={() => setSelectedTab('Archive')}>Archive</button>
      </div>
      <table className={styles.reviewTable}>
        <thead>
          <tr>
            <th>Game Title</th>
            <th>The Sandbox UserID</th>
            <th>Email</th>
            <th>Score</th>
            <th>Time</th>
            <th>Image</th>
            {selectedTab === 'Archive' ? (
              <th>Status</th>
            ) : (
              <th>Actions</th>
            )}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(scores) && scores.length > 0 ? (
            scores.map(score => (
              <tr key={score.id}>
                <td>{score.game_title}</td>
                <td>{score.tsb_uid}</td>
                <td>{score.email}</td>
                <td>{score.score}</td>
                <td>{score.time_seconds ? `${Math.floor(score.time_seconds / 60)}:${score.time_seconds % 60}` : 'N/A'}</td>
                <td>
                  <img
                    src={`/${score.image_url}`}
                    alt="User submission"
                    className={styles.thumbnail}
                    onClick={() => handleImageClick(`/${score.image_url}`)}
                  />
                </td>
                {selectedTab === 'Archive' ? (
                  <td className={score.status === 'Approved' ? styles.statusApproved : styles.statusRejected}>{score.status}</td>
                ) : (
                  <td>
                    {selectedTab === 'Pending' && (
                      <>
                        <button className={`${styles.button} ${styles.approve}`} onClick={() => handleApprove(score)}>Approve</button>
                        <button className={`${styles.button} ${styles.reject}`} onClick={() => handleReject(score)}>Reject</button>
                        <button className={`${styles.button} ${styles.mark}`} onClick={() => handleMark(score)}>Mark</button>
                      </>
                    )}
                    {selectedTab === 'Marked' && (
                      <>
                        <button className={`${styles.button} ${styles.approve}`} onClick={() => handleApprove(score)}>Approve</button>
                        <button className={`${styles.button} ${styles.reject}`} onClick={() => handleReject(score)}>Reject</button>
                      </>
                    )}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No scores to display.</td>
            </tr>
          )}
        </tbody>
      </table>
      {selectedImage && (
        <Modal
          isOpen={!!selectedImage}
          onRequestClose={closeModal}
          className={styles.modal}
          overlayClassName={styles.overlay}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
        >
          <img src={selectedImage} alt="Large preview" className={styles.largeImage} />
          <button onClick={closeModal} className={styles.closeButton}>X</button>
        </Modal>
      )}
    </div>
  );
};

export default Review;
