import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import styles from './Navbar.module.css';
import { UserContext } from './UserContext';

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const isLoggedIn = !!user;
  const isAdmin = user?.is_admin === 1;

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setUser(null);
    toast.success('Logout successful!');
    navigate('/');
  };

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  return (
    <div className={styles.navbarContainer}>
      <div className={styles.navbarTitle}>Trendy Nerds</div>
      <nav className={styles.navbar}>
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <Link className={styles.navLink} to="/">
              Main
            </Link>
          </li>
          <li
            className={styles.navItem}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <span className={styles.navLink}>
              Tops <FontAwesomeIcon icon={faCaretDown} className={styles.navIcon} />
            </span>
            {dropdownOpen && (
              <ul className={styles.dropdownMenu}>
                <li className={styles.dropdownItem}>
                  <Link className={styles.navLink} to="/leaderboard/trendy-factory">
                    Trendy Factory
                  </Link>
                </li>
                <li className={styles.dropdownItem}>
                  <Link className={styles.navLink} to="/leaderboard/trendy-docs">
                    Trendy Docs
                  </Link>
                </li>
              </ul>
            )}
          </li>
          {!isLoggedIn ? (
            <>
              <li className={styles.navItem}>
                <Link className={styles.navLink} to="/signup">
                  Sign Up
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link className={styles.navLink} to="/login">
                  Login
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className={styles.navItem}>
                <Link className={styles.navLink} to="/profile">
                  You
                </Link>
              </li>
              {isAdmin && (
                <li className={styles.navItem}>
                  <Link className={styles.navLink} to="/review">
                    Review
                  </Link>
                </li>
              )}
              <li className={styles.navItem}>
                <button className={`${styles.navLink} ${styles.logoutButton}`} onClick={handleLogout}>
                  Logout
                </button>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
