import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Auth.module.css';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    const response = await fetch('/api/reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, newPassword }),
    });

    if (response.ok) {
      toast.success('Password reset successful. You can now log in with your new password.');
      navigate('/login');
    } else {
      const error = await response.json();
      toast.error('Error: ' + error.message);
    }
  };

  return (
    <div className={styles.authContainer}>
      <ToastContainer />
      <h2 className={styles.authTitle}>Reset Password</h2>
      <form onSubmit={handleResetPassword} className={styles.authForm}>
        <input
          type="password"
          placeholder="Enter new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
          className={styles.authInput}
        />
        <button type="submit" className={styles.authButton}>Reset Password</button>
      </form>
    </div>
  );
};

export default ResetPassword;
