import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Profile.module.css';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [trendyFactoryData, setTrendyFactoryData] = useState({
    score: 0,
    estimatedReward: '0 SAND',
    position: 'N/A'
  });
  const [trendyDocsData, setTrendyDocsData] = useState({
    score: 0,
    estimatedReward: '0 SAND',
    position: 'N/A',
    time: 'N/A'
  });
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [notifyLeaderboardUpdates, setNotifyLeaderboardUpdates] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          const parsedUser = JSON.parse(storedUser);
          setUser(parsedUser);

          const factoryResponse = await fetch(`/api/leaderboard-position/Trendy%20Factory/${parsedUser.tsb_uid}`);
          if (factoryResponse.ok) {
            const factoryData = await factoryResponse.json();
            setTrendyFactoryData(factoryData);
            const estimatedFactoryReward = parseFloat(factoryData.estimatedReward || 0);
            const estimatedDocsReward = parseFloat(trendyDocsData.estimatedReward || 0);
            setTotalEarnings(estimatedFactoryReward + estimatedDocsReward);
          } else if (factoryResponse.status === 404) {
            setTrendyFactoryData({
              score: 0,
              estimatedReward: '0 SAND',
              position: 'N/A'
            });
          } else {
            console.error('Error fetching factory data:', factoryResponse.statusText);
          }

          const docsResponse = await fetch(`/api/leaderboard-position/Trendy%20Docs/${parsedUser.tsb_uid}`);
          if (docsResponse.ok) {
            const docsData = await docsResponse.json();
            setTrendyDocsData(docsData);
            const estimatedFactoryReward = parseFloat(trendyFactoryData.estimatedReward || 0);
            const estimatedDocsReward = parseFloat(docsData.estimatedReward || 0);
            setTotalEarnings(estimatedFactoryReward + estimatedDocsReward);
          } else if (docsResponse.status === 404) {
            setTrendyDocsData({
              score: 0,
              estimatedReward: '0 SAND',
              position: 'N/A',
              time: 'N/A'
            });
          } else {
            console.error('Error fetching docs data:', docsResponse.statusText);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);

  const handleNotificationChange = async () => {
    const newNotifyStatus = !notifyLeaderboardUpdates;
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/update-notifications', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ notify_leaderboard_updates: newNotifyStatus })
      });

      if (response.ok) {
        setNotifyLeaderboardUpdates(newNotifyStatus);
        toast.success('Notification preferences updated.');
        const updatedUser = { ...user, notify_leaderboard_updates: newNotifyStatus };
        setUser(updatedUser);
        localStorage.setItem('user', JSON.stringify(updatedUser));
      } else {
        toast.error('Failed to update notification preferences.');
      }
    } catch (error) {
      console.error('Error updating notification preferences:', error);
      toast.error('Error updating notification preferences.');
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className={styles.profileContainer}>
      <ToastContainer />
      <h2 className={styles.profileTitle}>Profile</h2>
      {user ? (
        <>
          <div className={styles.profileInfo}>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>The Sandbox UserID:</strong> {user.tsb_uid}</p>
          </div>
          <div className={styles.totalEarnings}>
            <h3>Total estimated earnings: {totalEarnings} SAND</h3>
          </div>
          <div className={styles.gameSummary}>
            <h3>Trendy Factory</h3>
            <p><strong>Score:</strong> {trendyFactoryData.score}</p>
            <p><strong>Estimated SAND Reward:</strong> {trendyFactoryData.estimatedReward}</p>
            <p><strong>Leaderboard Position:</strong> {trendyFactoryData.position}</p>
          </div>
          <div className={styles.gameSummary}>
            <h3>Trendy Docs</h3>
            <p><strong>Score:</strong> {trendyDocsData.score}</p>
            <p><strong>Time:</strong> {trendyDocsData.time}</p>
            <p><strong>Estimated SAND Reward:</strong> {trendyDocsData.estimatedReward}</p>
            <p><strong>Leaderboard Position:</strong> {trendyDocsData.position}</p>
          </div>
          <div className={styles.notificationToggle}>
            <label>
              <input
                type="checkbox"
                checked={notifyLeaderboardUpdates}
                onChange={handleNotificationChange}
              />
              Notify me about leaderboard updates
            </label>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Profile;
