import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import SubmissionModal from './SubmissionModal';
import styles from './Home.module.css';

Modal.setAppElement('#root');

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState('');
  const [email, setEmail] = useState('');
  const [tsbUid, setTsbUid] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setEmail(user.email);
      setTsbUid(user.tsb_uid);
    }
  }, []);

  const handleSubmitScoreClick = (game) => {
    const storedUser = localStorage.getItem('user');
    if (!storedUser) {
      navigate('/login');
    } else {
      setSelectedGame(game);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.homeContainer}>
      <div className={styles.welcomeContainer}>
      <div className={styles.mainMessage}>
        We build fun games on <span className={styles.sandboxGame}>The&nbsp;Sandbox&nbsp;Game</span> platform.
      </div>
        <div className={styles.subMessage}>
          In the Last Builders Challenge, our game, Trendy Factory, placed on top and <br />
          helped 54 Trendy Nerd players earn a total of 32,346.23 SAND
        </div>
      </div>
      <div className={styles.gameSection}>
        <div className={styles.block}>
          <div className={styles.imageContainer}>
            <img src="/images/TrendyBlock.png" alt="Trendy Factory" className={styles.gameImage} />
          </div>
          <div className={styles.blockTitle}>Trendy Factory</div>
          <div className={styles.blockSubtitle}>Up to 40,000 Sand Pool</div>
          <div className={styles.buttonContainer}>
            <Link to="https://www.sandbox.game/play/trendy-factory" className={`${styles.gameButton} ${styles.playButton}`}>
              Play
            </Link>
            <button className={`${styles.gameButton} ${styles.submitButton}`} onClick={() => handleSubmitScoreClick('Trendy Factory')}>
              Submit
            </button>
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.imageContainer}>
            <img src="/images/TrendyBlock.png" alt="Trendy Docs" className={styles.gameImage} />
          </div>
          <div className={styles.blockTitle}>Trendy Docs <span className={styles.newLabel}>(New)</span></div>
          <div className={styles.blockSubtitle}>Up to 40,000 Sand Pool</div>
          <div className={styles.buttonContainer}>
            <Link to="https://www.sandbox.game/play/trendy-docs" className={`${styles.gameButton} ${styles.playButton}`}>
              Play
            </Link>
            <button className={`${styles.gameButton} ${styles.submitButton}`} onClick={() => handleSubmitScoreClick('Trendy Docs')}>
              Submit
            </button>
          </div>
        </div>
      </div>
      <SubmissionModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        game={selectedGame}
        email={email}
        tsbUid={tsbUid}
      />
    </div>
  );
};

export default Home;
