import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from './UserContext'; // Import UserContext
import styles from './Auth.module.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forgotEmail, setForgotEmail] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext); // Use UserContext to update user state

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });
      const data = await response.json();
      if (data.token) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        console.log('Login successful, token stored:', data.token);
        setUser(data.user); // Update user context
        toast.success('Login successful!'); // Show success notification
        navigate('/'); // Redirect to landing page
      } else {
        console.error('Login failed:', data.message);
        toast.error('Login failed: ' + data.message); // Show error notification
      }
    } catch (error) {
      console.error('Error during login:', error);
      toast.error('Error during login: ' + error.message); // Show error notification
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    const response = await fetch('/api/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: forgotEmail }),
    });

    if (response.ok) {
      toast.success('Password reset link sent to your email.'); // Show success notification
      setForgotPassword(false);
    } else {
      const error = await response.json();
      toast.error('Error: ' + error.message); // Show error notification
    }
  };

  return (
    <div className={styles.authContainer}>
      <h2 className={styles.authTitle}>Login</h2>
      {forgotPassword ? (
        <form onSubmit={handleForgotPassword} className={styles.authForm}>
          <input
            type="email"
            placeholder="Enter your email"
            value={forgotEmail}
            onChange={(e) => setForgotEmail(e.target.value)}
            required
            className={styles.authInput}
          />
          <button type="submit" className={styles.authButton}>Send Reset Link</button>
          <button type="button" onClick={() => setForgotPassword(false)} className={styles.authButton}>Back to Login</button>
        </form>
      ) : (
        <form onSubmit={handleLogin} className={styles.authForm}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={styles.authInput}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={styles.authInput}
          />
          <button type="submit" className={styles.authButton}>Login</button>
          <button type="button" onClick={() => setForgotPassword(true)} className={styles.authButton}>Forgot Password?</button>
        </form>
      )}
    </div>
  );
};

export default Login;
